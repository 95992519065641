
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
  data() {
    return {
      authenticated: false,
    };
  },
  mounted() {
    if (!this.authenticated) {
      this.$router.replace({ name: "login" });
    }
  },
  methods: {
    setAuthenticated(status: any) {
      this.authenticated = status;
    },
    logout() {
      this.authenticated = false;
    },
  },
});
