import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: "/admin",
  //   name: "Admin",
  //   component: () => import('../views/Admin.vue'), beforeEnter: checkAuth
  // },
  // {
  //   path: "/",
  //   name: "Home",
  //   component: () => import('../views/Home.vue')
  // },
  // {
  //   path: "/about",
  //   name: "About",
  //   component: () => import('../views/About.vue'),
  // },
  // {
  //   path: "/login",
  //   name: "Login",
  //   component: () => import('../views/Login.vue'),
  // },
  // {
  //   path: "/register",
  //   name: "Register",
  //   component: () => import('../views/Register.vue'),
  // },
  // {
  //   path: "/cart",
  //   name: "Cart",
  //   component: () => import('../views/Cart.vue'),
  // },
  // {
  //   path: '/shop',
  //   name: 'shopSingle',
  //   component: () => import('../views/Shop.vue'),
  // },
  // {
  //   path: '/shop/:id',
  //   name: 'shopSingle',
  //   component: () => import('../views/Shop.vue'),
  // },





  {
    path: "/",
    name: "Home",
    component: () => import('../views/temporary/MugOrderForm.vue')
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from, next) => {
//   // if(to.matched.some(record => record.meta.requiresAuth)) {
//   //   // if (isAuthenticated) {
//   //     // next()
//   //     to
//   // }
// })
function checkAuth(to: any, from: any, next: any) {
  if (window.localStorage.currentUser !== undefined) {
    const currentUser = JSON.parse(window.localStorage.currentUser);
    if (currentUser && currentUser.name) {
      next()
    } else {
      next("/login")
    }
  }
}
export default router;
